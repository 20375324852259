"use client";
import dynamic from "next/dynamic";
const CustomComponents = dynamic(() => import("../CustomComponents"), {
  ssr: false,
});
interface MainComponentProps {
  data: any[];
}
export default function MainComponent({ data }: MainComponentProps) {
  return (
    <section className="flex flex-col space-y-4">
      <CustomComponents data={data} />
    </section>
  );
}
